import { z } from 'zod';
import { addressDocumentSchema } from './address';

export const productDocumentSchema = z.object({
  brand: z.string(),
  bumps: z.number(),
  categories: z.array(z.string()),
  condition: z.string(),
  country_code: z.string(),
  curated_lists: z.array(z.string()).optional(),
  created: z.number(),
  date_added: z.number(),
  date_updated: z.number(),
  description: z.string().optional(),
  description_html: z.string().optional(),
  favorite_count: z.number().default(0),
  from_web: z.boolean(),
  id: z.string(),
  images: z.array(
    z.object({
      thumb: z.string(),
      full: z.string(),
    })
  ).default([]),
  is_draft: z.boolean(),
  is_fraudulent: z.boolean().default(false),
  is_expired: z.boolean().default(false),
  is_verified: z.boolean().default(false),
  is_featured: z.boolean().default(false),
  is_flat_rate: z.boolean(),
  is_auction: z.boolean().optional().default(false),
  is_auction_live: z.boolean().optional().default(false),
  integrations: z.object({
    source: z.string(),
    id: z.string(),
    date_synced: z.number(),
    last_synced: z.number()
  }).optional(),
  start_time: z.number().optional(),
  end_time: z.number().optional(),
  minimum_offer_price: z.number(),
  offers_enabled: z.boolean(),
  og_price: z.number(),
  old_id: z.number(),
  old_seller_id: z.number(),
  on_sale: z.boolean(),
  on_vacation: z.boolean().optional(),
  out_of_stock: z.boolean(),
  price: z.number(),
  seller_id: z.string(),
  sold_date: z.number().optional(),
  source: z.string(),
  account_id: z.string().optional(), //old sellers don't have this
  shipment: z.object({
      ship_from: addressDocumentSchema,
      package: z.object({
        weight: z.object({ unit: z.literal('ounce'), value: z.number() }),
        dimensions: z.object({
          unit: z.literal('inch'),
          length: z.number(),
          width: z.number(),
          height: z.number(),
        }),
        insured_value: z
          .object({ currency: z.literal('usd'), amount: z.number() })
          .optional(),
      }),
    }).optional(),
  shipping_costs: z.array(z.object({ 
    cost: z.number(),
    code: z.string(),
    name: z.string()
  })).optional(),
  sku: z.string(),
  slug: z.string(),
  statuses: z.array(z.object({
    created_at: z.number(),
    status: z.string()
  })).optional(),
  stock: z.number(),
  taxable: z.boolean(),
  thumbnail: z.string(),
  title: z.string(),
  master: z.string().optional().nullable(),
  counter: z.object({ 
    views: z.number().optional() 
  }).optional(),
  external_id: z.number().optional(),
  external_source: z.string().optional(),
  rank_score: z.number().default(0)
});

export const draftProductDocumentSchema = productDocumentSchema.partial();
export type ProductDocument = z.infer<typeof productDocumentSchema>;
