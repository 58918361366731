import Image, { ImageProps } from 'next/image';
import { useState, useCallback, memo } from 'react';

interface SafeImageProps extends ImageProps {
  alt: string;
  height?: number;
  width?: number;
  src: string;
  fallbackSrc?: string;
  classes?: string;
  priority?: boolean;
}

const ERROR_MAX_WIDTH = 96;

const SafeImage = ({
  src,
  height = 144,
  width = 144,
  alt,
  fallbackSrc = '/not_found.svg',
  fill = false,
  classes = '',
  priority = false,
  loading,
  ...imageProps
}: SafeImageProps) => {
  const [imgProps, setImgProps] = useState({
    width,
    height,
    src,
  });

  // Memoize the error handler to prevent recreation on rerenders
  const handleOnError = useCallback(() => {
    setImgProps({
      width: Math.min(ERROR_MAX_WIDTH, width),
      height: Math.min(ERROR_MAX_WIDTH, width),
      src: fallbackSrc || '/not_found.svg',
    });
  }, [fallbackSrc, width]);

  // Compute dimension props only once
  const dimensionProps = fill
    ? { fill: true }
    : { height: imgProps.height, width: imgProps.width };

  // Determine loading strategy
  const loadingStrategy = priority ? 'eager' : loading || 'lazy';

  // Use Next.js Image with optimal settings
  return (
    <Image
      alt={alt}
      src={imgProps.src || '/not_found.svg'}
      onError={handleOnError}
      {...dimensionProps}
      className={classes}
      priority={priority}
      {...imageProps}
      loading={loadingStrategy}
      itemProp="image"
      sizes={imageProps.sizes || "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"}
      quality={imageProps.quality || 75}
    />
  );
};

// Export a memoized version of the component to prevent unnecessary re-renders
export default memo(SafeImage);
