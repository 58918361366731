interface CategorySkeletonProps {
  className?: string;
  loading?: boolean;
}

const ShimmerCard = () => (
  <div className="flex max-w-[34.3rem] flex-col gap-[1.2rem] sm:max-w-[21.1rem]">
    <div className="aspect-square h-[32rem] animate-pulse rounded-[1.6rem] bg-brand-light-gray sm:max-h-[21.2rem]"></div>
    <div className="flex h-[7.7rem] flex-col gap-[.4rem]">
      <div className="h-[3rem] w-11/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
      <div className="h-[3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
    </div>
  </div>
);

const FilterSkeleton = () => {
  return (
    <div className="flex h-min w-full flex-col gap-[2rem]">
      <div
        className={`
          w-full py-[0rem] lg:py-[1.4rem]
          pt-[3rem] pb-[1.5rem] lg:py-[1.4rem]
        `}
      >
        <div
          className="
            w-full max-w-default
            gap-[1rem] lg:gap-0
            flex flex-col-reverse sm:flex-row mx-auto
            items-end sm:items-center sm:justify-between
          "
        >
          {/* Side Filters */}
          <div className="w-full">
            <div className="flex flex-col sm:flex-row gap-[1rem] w-full">
              <div className="h-[3.2rem] w-full sm:w-[12rem] rounded-[1.6rem] bg-brand-light-gray" />
              <div className="flex gap-[1rem] w-fit-content">
                <div className="h-[3.2rem] w-[8rem] rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[3.2rem] w-[8rem] rounded-[1.6rem] bg-brand-light-gray" />
              </div>
            </div>
          </div>

          {/* Controls */}
          <div className="hidden sm:flex w-full gap-[0.8rem] self-baseline sm:w-auto lg:gap-[1rem]">
            <div className="flex w-full flex-col gap-[1.6rem] sm:flex-row">
              <div className="flex gap-[1.6rem]">
                <div className="h-[3.2rem] w-[10rem] rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[3.2rem] w-[7rem] rounded-[1.6rem] bg-brand-light-gray" />
              </div>
              <div className="h-[3.2rem] w-[5rem] rounded-[1.6rem] bg-brand-light-gray" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CategorySkeleton = ({ }: CategorySkeletonProps) => {
  return (
    <div className="w-full">
      {/* Page Header */}
      <div className="bg-brand-black h-[15rem] sm:h-[28.7rem]" />

      {/* Main Content Wrapper */}
      <div className="max-w-default px-[2rem] md:px-[4rem] xl:px-default-padding-x mt-[2rem]">
        <div className="flex gap-[2.4rem]">
          {/* Top Bar & Filters */}
          <div className="flex w-full flex-col gap-[1.2rem]">
            {/* Filters & Controls Skeleton */}
            <FilterSkeleton />

            {/* Product Grid Skeleton */}
            <div className="h-min-content mt-[4rem] flex flex-col items-center gap-[1.6rem] sm:flex-row sm:flex-wrap sm:items-start sm:justify-center sm:overflow-auto">
              {Array.from({ length: 12 }).map((_, index) => (
                <ShimmerCard key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySkeleton;
