import BaseDrawer from '@c/drawers/BaseDrawer';
import Button from '@ui/Button';
import CloseButton from '@c/icons/buttons/CloseButton';
import SafeImage from '@ui/SafeImage';
import { CartIcon, MinusIcon, PlusIcon, TrashIcon } from '@c/icons';
import { formatCurrency } from '@util/index';
import { getCartItemFromProductDocument } from '@util/index';
import { ProductDocument } from '@util/types/firestore/products';
import { useShoppingCart } from 'context/ShoppingCartContext';
import Link from 'next/link';
import { getProductUrl } from '@util/urlHelpers';
import { useMemo } from 'react';

interface ShoppingCartProps {
  onClose?: () => void;
}

const Counter = ({
  val,
  increment,
  decrement,
}: {
  val: number;
  increment: () => void;
  decrement: () => void;
}) => {
  return (
    <div className="flex w-[9.6rem] items-center justify-between rounded-full border-[1px] border-brand-lighter-gray px-[1.2rem] py-[0.6rem]">
      <button className="mr-auto h-[2rem] w-[2rem]" onClick={decrement}>
        <MinusIcon />
      </button>
      {val}
      <button className="ml-auto h-[2rem] w-[2rem]" onClick={increment}>
        <PlusIcon />
      </button>
    </div>
  );
};

const CartItemDisplay = ({
  product,
  qty,
}: {
  product: ProductDocument;
  qty: number;
}) => {
  const { increaseCartQty, decreaseCartQty, removeFromCart } =
    useShoppingCart();
  return (
    <div className="flex gap-[1.6rem]">
      <Link href={getProductUrl(product.slug)} className="h-[10rem] w-[10rem]">
        <SafeImage alt={product.title} src={product.thumbnail} />
      </Link>
      <div className="flex w-full flex-col gap-[0.4rem] overflow-hidden">
        <Link href={getProductUrl(product.slug)}>
          <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.5rem] font-normal">
            {product.title}
          </p>
        </Link>
        <div className="flex items-center justify-end gap-[1.6rem]">
          <p className="mr-auto font-semibold">
            {formatCurrency(product.price)}
          </p>
          <Counter
            val={qty}
            increment={() => {
              const cartItems = getCartItemFromProductDocument(product) 
              if (cartItems) increaseCartQty(cartItems)
            }
              
            }
            decrement={() => decreaseCartQty(product.id)}
          />
          <button onClick={() => removeFromCart(product.id)}>
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

const ShoppingCart = ({}: ShoppingCartProps) => {
  const { cartOpen, setCartOpen, cart, realTimeProducts } = useShoppingCart();
  
  // Get product quantity from cart items
  const getProductQty = (productId: string) =>
    cart.items.find((i) => i.product_id === productId)?.qty ?? 0;

  // Filter realTimeProducts to only show products that are in the cart
  const cartProducts = useMemo(() => 
    realTimeProducts.filter(product => 
      cart.items.some(item => item.product_id === product.id)
    ),
    [realTimeProducts, cart.items]
  );

  return (
    <BaseDrawer
      show={cartOpen}
      side={'right'}
      dismiss={() => setCartOpen(false)}
    >
      {/* fixed z-40 h-screen right-0 */}
      <div className="flex h-full w-full flex-col gap-[2.4rem] divide-y overflow-y-auto overflow-x-clip bg-brand-white font-semibold text-brand-black sm:pb-[17rem]">
        <div className="flex justify-between px-[1.6rem] pt-[2.4rem] xl:px-[2.4rem]">
          <h5 className="font-semibold">Shopping Cart</h5>
          <CloseButton onClick={() => setCartOpen((prev) => !prev)} />
        </div>
        {/* empty state */}
        {!cart.items.length && (
          <div className="mt-auto flex h-full flex-col items-center justify-center">
            <div className="flex flex-col items-center gap-[3.2rem]">
              <div className="text-brand-lighter-gray">
                <CartIcon width={64} height={64} />
              </div>
              <div className="flex flex-col items-center gap-[0.8rem] p-[0.8rem]">
                <p>No Items</p>
                <p className="text-center font-normal">
                  Visit our product page and add items to this cart.
                </p>
              </div>
              <Button
                onClick={() => setCartOpen((prev) => !prev)}
                href="/products"
                text="Continue Shopping"
                type="secondary"
              />
            </div>
          </div>
        )}
        {/* not empty state */}
        {!!cart.items.length && (
          <>
            {/* items */}
            <div className="pt-[2.4rem]">
              <div className="px-[1.6rem] xl:px-[2.4rem]">
                {cartProducts.map((p) => (
                  <CartItemDisplay
                    key={p.id}
                    product={p}
                    qty={getProductQty(p.id)}
                  />
                ))}
              </div>
            </div>

            {/* total/checkout */}
            <div className="inset-x-0 bottom-0 z-50 bg-brand-white pt-[2.4rem] sm:fixed sm:mt-auto">
              <div className="flex flex-col gap-[2rem] px-[2.4rem] pb-[2.4rem]">
                <div className="flex items-center justify-between">
                  <p className="text-[1.5rem]">Total (USD)</p>
                  <p className="text-[2.4rem]">
                    {formatCurrency(cart.subtotal)}
                  </p>
                </div>
                <Button
                  text="Proceed to Checkout"
                  href="/checkout"
                  height="small"
                  type="secondary"
                  onClick={() => setCartOpen((prev) => !prev)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </BaseDrawer>
  );
};

export default ShoppingCart;
