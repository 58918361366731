import { ProductDocument } from '@util/types/firestore/products';
import { onSnapshot } from 'firebase/firestore';
import { useMemo, useState, useEffect } from 'react';
import { getProductsByIdQuery } from '@util/firestore/products';

const useRealTimeProducts = (ids: string[]) => {
  const [realTimeProducts, setProducts] = useState<ProductDocument[]>([]);
  
  // Only create query if we have IDs to search for
  const productsQuery = useMemo(
    () => ids.length ? getProductsByIdQuery(ids) : null,
    [ids]
  );

  useEffect(() => {
    // Reset products if no IDs or query
    if (!ids.length || !productsQuery) {
      setProducts([]);
      return;
    }

    const unsubscribe = onSnapshot(productsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => doc.data());
      setProducts(realTimeData);
    });

    return () => {
      unsubscribe();
    };
  }, [productsQuery, ids]);

  return {
    realTimeProducts,
  };
};

export default useRealTimeProducts;
