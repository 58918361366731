import { serializeError } from 'serialize-error';
export const logError = async (
  err: Error | string | unknown,
  id = generateRandomId(),
  componentStack?: string
) => {
  try {
    console.error(err);
    const ctx = serializeError(err);
    if (componentStack) ctx.stack = componentStack;
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      await fetch('/api/error', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...serializeError(err), id }),
      });
    }
  } catch (e) {
    console.error('Error reporting error', e);
  }
};

export const generateRandomId = () => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 24; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};
